import React, { Component } from 'react'

export class FooterOne extends Component {
    render() {
        let FooterData = this.props.FooterData;
        return (
            <footer className="footer-wrapper footer-one">
                <div className="footer-widgets-wrapper section-bg text-white vFooterBg">
                    <div className="container">
                        <div className="row">

                        <div className="col-sm-6 col-lg-6 col-12" >
                                            <div className="single-footer-widget">
                                                <div className="widegts-content">
                                                    <div className='vLogoFooter'>
                                                        <img src={require("../../assets/img/whatsinaname/Headerlogo.png")} alt="" />
                                                        <p>
                                                            Sleep Therapeutics is a revolutionary approach to treating sleep disorders. Carving out a niche in the Telugu states, Sleep Therapeutics has taken a bold stand on the need for deep and quality sleep in our society.
                                                        </p>
                                                        <p>
                                                            We take our citizen’s sleep issues seriously for their and the nation’s well-being.
                                                        </p>
                                                        <p className='socialMedia'>
                                                            <a href="https://www.facebook.com/profile.php?id=100089385787735" target='_blank'><i class="fab fa-facebook"></i></a>
                                                            <a href="https://www.instagram.com/sleeptherapeuticsindia/" target='_blank'><i class='fab fa-instagram'></i></a>
                                                            <a href='https://www.linkedin.com/company/sleeptherapeuticsindia' target='_blank'><i class='fab fa-linkedin'></i></a>
                                                            <a href='https://twitter.com/sleeptherap' target='_blank'><i class='fab fa-twitter'></i></a>
                                                            <a href='https://www.youtube.com/@sleeptherapeuticsindia' target='_blank'><i class="fab fa-youtube"></i></a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

{
                                FooterData.singleWidget.map( (widget, index) => {
                                    return (
                                        
                                        <div className={`col-sm-6 col-lg-2 col-12`} >
                                            <div className="single-footer-widget">
                                                <div className="f-widget-title">
                                                    <h2  className='vBlackColr' key={widget.id}>{widget.title}</h2>
                                                </div>
                                                <div className="widegts-content">
                                                    {
                                                        widget.menuItems.map(item => {
                                                            return (
                                                                <a href={item.url} key={item.id}>{item.title}</a>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        
                                    )
                                })
                            }

                            <div className="col-sm-6 col-lg-4 col-12">
                                <div className="single-footer-widget">
                                    <div className="f-widget-title">
                                        <h2 className='vBlackColr'>Our Address</h2>
                                    </div>
                                    <div className="widegts-content">
                                        <p>Plot no.11, Filmnagar, 8-2-293/82,
                                <br /> Road No. 82, J-III, Jubilee Hills,<br/>Hyderabad - 500 033, Telangana</p>
                                        <li>888 666 8162 & 888 666 8164
                                            <p>(Monday - Saturday 10am-8pm)</p>
                                            <p>(Sunday - 10am -2pm)</p>
                                        </li>
                                        <li>info@sleeptherapeutics.in</li>
                                    </div>
                                </div> 
 

                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright-wrapper text-center">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='vFooterBottom'>
                                    <p>&copy; {FooterData.copyright}</p>
                                    <p>Website Designed & Developed by <a href="https://www.whatsinaname.in/" target='_blank'>What’s In a Name</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default FooterOne
